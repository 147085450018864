body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
body {
  /* linear-gradient(#00000087,#00000087), */
  background-image:  url(/static/media/wnbgqual.ea2f33d8.jpg);
  /* background-position: center; */
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-color: #192536;
  opacity: 1;
  min-height: 900px;
  
}
/* #root{
      border-radius: 5px;
  background-image: url("./wp.jpg");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #192536;
  opacity: 1;
  min-height: 900px;
} */
.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}
.app{
  text-align: center;
  min-height: 70px;
  min-width: 70px;
}
.btn{
  margin-top: 50px;
  min-height: 20px;
  min-width: 70px;
  background-color: rgb(34, 92, 47);
  color: rgb(183, 194, 164);
}
.login_button{
  width: 221px;
  border: 2px solid #7289da;
  color: #fff;
  padding: 14px;
  font-size: 27px;
  background-color: #5865f2;
  color: #dcddda;
  box-shadow: 0 2px 6px 0 rgb(0 0 0 / 20%);
}
.login_button:hover{
  background-color: #3847e2;
}
.login_div{
  color: #b7c2a4;
    display: flex;
    justify-content: center;
    /* width: 100%; */
    height: 400px;
    flex-direction: column;
    align-items: center;

}
.login_p{
  margin: 0px;
  padding: 20px;
  display: inline-grid;
  justify-content: center;
  /* width: 100%; */
  /* height: 400px; */
  /* flex-direction: ; */
  align-items: center;
  /* min-height: 50px; */
  background-color: #00041e;
  align-content: center;
  justify-items: center;
}

.login_wrapper{
  width: 372px;
  border-radius: 12px;
  margin: 0px;
  padding: 20px;
  display: inline-grid;
  justify-content: center;
  /* width: 100%; */
  /* height: 400px; */
  /* flex-direction: ; */
  align-items: center;
  /* min-height: 50px; */
  background-color: #00041e;
  align-content: center;
  justify-items: center;
}



.btn_red{
  margin-top: 50px;
  min-height: 20px;
  min-width: 70px;
  background-color: rgb(156, 88, 31);
  color: rgb(183, 194, 164);
}
.navbar{
  width: 100%;
 
  background-color: #000000e0;;
  display: inline-flex;
  min-height: 32px;
}

@-webkit-keyframes flip-horizontal-bottom {
  0% {
    -webkit-transform: rotateX(0);
            transform: rotateX(0);
  }
  100% {
    -webkit-transform: rotateX(-180deg);
            transform: rotateX(-180deg);
  }
}
@keyframes flip-horizontal-bottom {
  0% {
    -webkit-transform: rotateX(0);
            transform: rotateX(0);
  }
  100% {
    -webkit-transform: rotateX(-180deg);
            transform: rotateX(-180deg);
  }
}



.wars_all_container{
  margin-top: 12px;
  display: inline-flex;
  flex-direction: column;
}


table.fixed {table-layout:fixed; width:inherit;
  border-radius: 5px;
  caret-color: rgba(0,0,0,0);
  /* -webkit-animation: flip-horizontal-bottom 0.4s cubic-bezier(0.455, 0.030, 0.515, 0.955) both;
  animation: flip-horizontal-bottom 0.4s cubic-bezier(0.455, 0.030, 0.515, 0.955) both; */
}/*Setting the table width is important!*/
table.fixed td {overflow:hidden;}/*Hide text outside the cell.*/
table.fixed td:nth-of-type(1) {width:35px;}/*Setting the width of column 1.*/
table.fixed td:nth-of-type(2) {width:40px;}/*Setting the width of column 2.*/
table.fixed td:nth-of-type(3) {width:130px;}/*Setting the width of column 3.*/
table.fixed td:nth-of-type(4) {width:90px;}
table.fixed td:nth-of-type(5) {width:90px;}
table.fixed td:nth-of-type(6) {width:70px;}
table { 
  border-collapse: collapse; 
} 
.navbar_buttons{
  caret-color: rgba(0,0,0,0);
  cursor: context-menu;
  margin-left: 25px;
  color:#b3b3b3;
  margin:0px;
  font-size: 18px;
  max-height: 50px;
  padding: 4px;
  display: inline-flex;
  align-items: center;
}

.navbar_buttons:hover{
  caret-color: rgba(0,0,0,0);
  color:rgb(224, 223, 223);
  text-shadow:2px 2px 2px black;
}
.navbar_buttons:active{
  color:rgb(250, 250, 250);
  text-shadow:2px 2px 2px black;
 
}
.navbar_button_active{
  caret-color: rgba(0,0,0,0);
  cursor: context-menu;
  margin-left: 25px;
  color:#e9e9e9;
  margin:0px;
  font-size: 18px;
  max-height: 50px;
  padding: 4px;
  display: inline-flex;
  align-items: center;
  text-decoration: underline;
}
.select_war{
border-collapse: collapse;
  
  display: inline-flex;
   
    flex-direction: column;
}
.list{
  display: block;
  text-shadow: 1px 1px 2px #000;
}

.option{
  text-align: center;
  border: 0px;
  outline:0 !important;
  background-color: #19253600;
  border-radius: 3px;
  color: #b3b3b3;
  background-size: cover;
  display: inline-table;
  font-size: 18px;
  
}

.options:checked {
  box-shadow: 0 0 10px 100px #00041e inset;
  border-radius: 3px;
  text-shadow: 1px 1px 2px black;
  color: #d3d3d3;
}
.options:hover{
  text-shadow: 1px 1px 2px black;
  text-align: center;
  color: #d3d3d3;
}
.search_input{
  outline:0 !important;
  border-radius: 5px;
  background-color: #00000038;
    width: -webkit-fill-available;
    border: 0px;
    color: #bbbbbb;
    padding: 8px;
}
.search_bar{
  border-radius: 5px;
  display: inline-flex;
  flex-direction: row;
    flex-wrap: nowrap;
    cursor: context-menu;
  outline:0 !important;
  border-radius: 5px;
  margin-top: 10px;
  background-color: #000000b5;
  width: inherit;
  max-height: 170px;
  flex-direction: row;
    flex-wrap: nowrap;
}
.look_glass{
  border-radius: 5px;
  background-image:  url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADwAAAA8BAMAAADI0sRBAAAABGdBTUEAALGPC/xhBQAAAAFzUkdCAK7OHOkAAAAqUExURUdwTP///////////////////////////////////////////////////4sGkswAAAANdFJOUwALy4Q6nfki71Zps9+fxCyzAAABfklEQVQ4y62Vu0oDQRSGJ4m6Rl3wBQLBfiG9BBasLIQ0lgvWipAXEPICQjrbrYWAYC34AoFNjCag513cuTB7LjPb6Okm38zO/8+5RKl/i8vnpyjrFKDjNkyPwMXuNED7AG38vsHwKegV4LhhtAc02OfHDK/CurwCggdWcd5cQ27HfvaEeHti7lZdLi6laozOWYPf6PYe066XW3TZsl6vqa0XxS7zqwNu1OzP8eZvxY0O8aNUBBf4l4XA5ID29U5wirH2MRHYO32sFyOBN7iOyjheBk9vsbRhXNpYGCP4QWByQO/9EI86CyeojgSwF5P+UTShile2zsgXrQ6029Tqhn2sov2GrNDStWU95W3gKr+jM4Qq0bev4YkRQp9p3zXe3XUW7PCMNTDsCO5yzFJYcEznSyKOzwk/RiTjTUeuL09C46dvB8t66oZcJSbj+dlr7pMA8dEMbECwGIh3J2HH0yTKFwFvPEk/cXFZu/bDVm3G26oFp2g4heKi/PMf3y9u1NXJlPOSNAAAAABJRU5ErkJggg==);
  background-position: center;
  background-size: 20px;
  
  background-repeat: no-repeat;
  background-color: #000000;
  width: 34px;
  min-height: 20px;
  opacity: 0.8;
}
.x_cancel{
  border-radius: 5px;
  margin-top: 2px;
  text-align: center;
  background-color: #000000;
  color: #616161;
  width: 34px;
  min-height: 20px;
  opacity: 0.8;
}
.x_cancel:hover{
  color: #878787;
  opacity: 1;
}
.Heatmap{
  /* height: 47vh; */
  padding-top: 16px;
  width: 50%;
  font-size: 9px;
  /* margin-top: 5px; */
  color: #606060;
  background-color: #000000 !important;
  border-radius: 3px;
}
.options:active{
  background: linear-gradient(#ffc89400,#ffc89400);
  border-radius: 3px;
    background-color: #00041e !important; /* for IE */
  text-shadow: 1px 1px 2px black;
  text-align: center;
  color: #d3d3d3;
}
.list:hover{
  text-shadow: 1px 1px 2px black;
  text-align: center;
  color: #d3d3d3;
}
/* .list:active, */
.tr_user:hover{
  background-color: #165cb74a;

}
.list:checked {
  border-radius: 3px;
  box-shadow: 0 0 10px 100px #00041e inset;
  /* background: linear-gradient(#ce0c0c00,#ce0c0c00); */
  /* background: transparent !important; */
  /* background: linear-gradient(#ffc89400,#ffc89400); */
  background-color: #00ff0000 !important; /* for IE */
  text-shadow: 1px 1px 2px black;
  text-align: center;
  color: #d3d3d3;
}

.tr_bg{
  border-radius: 5px;
}
.found_names:hover{
  color: white;
  cursor: pointer;
}
.graphs {
  background-color: #000000e0;
  border-radius: 5px;
  width: 33%;
  margin: 0px;
  /* margin-top: 0px; */
 /* // transform: rotate(90deg); */
}
.graphschart{
  width: inherit;
  height: 37vh;
  /* margin-right: inherit; */
  background-color: #000000e0;
  margin: 0px;
}
.columnleft {
  width: 58px;
  float: left;
  /* width: 10%; */
}
.columnright {
  /* max-width: 60%; */
  display: inline-flex;
  margin-top: 5px;
  margin-right: 5px;
  /* float: right; */
  /* margin-left: 75px; */
  /* width: 90%; */
}
.wrapper_col_right{
  width: 112vh;
  /* flex-grow: 1; */
  /* width: 65%; */
  flex-direction: column;
  margin-left: 5px;
  margin-right: 22px;
}
#hours {
  background-color: #000000e0;
  border-radius: 5px;
  margin-left: 5px;
}

.row{
  display: flex;
    flex-direction: row;
    /* width: 99%; */
    justify-content: center;
}

/* Clear floats after the columns */
/* .row:after {
  content: "";
  display: table;
  clear: both;
} */
.truediv {
  box-sizing: border-box;
  display: inline-block;
  width: 50%;
}
.wrapper {
  height: 47vh;
  position: fixed;
  width: inherit;
  /* min-height: 251px; */
  /* margin: 4px; */
  display: inline-flex;
  
  /* /height: min-content; */
  /* justify-content: flex-start;
  align-items: flex-start; */
}
.wrapper_bottom{
  width: inherit;
  display: inline-flex;
}
.wrapperRanks {
  width: inherit;
  min-height: 251px;
  /* margin: 4px; */
  display: inline-flex;
  
  /* /height: min-content; */
  /* justify-content: flex-start;
  align-items: flex-start; */
}
.topDiv{
  position: relative;
  width: 439px;
  display: inline-flex;
  flex-direction: column;
  flex-wrap: wrap;
}
.PlayersLength{
  text-shadow: 1px 1px 2px #00000000;
}
@-webkit-keyframes swing-in-top-fwd {
  0% {
    -webkit-transform: rotateX(-100deg);
            transform: rotateX(-100deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 1;
  }
}
@keyframes swing-in-top-fwd {
  0% {
    -webkit-transform: rotateX(-100deg);
            transform: rotateX(-100deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateX(0deg);
            transform: rotateX(0deg);
    -webkit-transform-origin: top;
            transform-origin: top;
    opacity: 1;
  }
}

/* .PlayersPD {
  animation: swing-in-top-fwd 5s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
} */

.razar {
  top: 37%;
     position:absolute; 
   /* width: 100%; */
  height: 280px; 
  background-size: 100% 100%;
  border-radius:35px;
  /* box-shadow:0 1px 1px 0 rgba(0,0,0,0.4), 0 0 4px 1px rgba(0,0,0,0.2), inset 0 1px 1px 0 rgba(255,255,255,0.2), inset 0 2px 4px 1px rgba(255,255,255,0.1); */
}

.pulse {
   position:absolute; 
  top:0;
  left:0;
  width:70px;
  height:70px;
  border-radius:35px;
  background:#8af48f00;
  -moz-animation: pulsating 2s ease-in-out;
  -moz-animation-iteration-count: infinite;
  -webkit-animation: pulsating 2s ease-in-out;
  -webkit-animation-iteration-count: infinite;
  opacity:0.0;
  z-index:5;
}

.ringbase {
  /* position:absolute; */
  top:0;
  left:0;
  width:70px;
  height:70px;
  border-radius:35px;
  opacity:0.0;
  z-index:10;
}

.ring1 {
  box-shadow:0 0 2px 1px #727ae534, inset 0 0 2px 1px #727ae534;
  -moz-animation: ring 2s ease-in-out;
  -moz-animation-iteration-count: infinite;
  -webkit-animation: ring 2s ease-in-out;
  -webkit-animation-iteration-count: infinite;
  animation: ring 2s ease-in-out;
  animation-iteration-count: infinite;
}

.ring2 {
  box-shadow:0 0 1px 0px #727ae534, inset 0 0 1px 0px #727ae534;
  -moz-animation: ring 2s ease-in-out;
  -moz-animation-iteration-count: infinite;
  -moz-animation-delay: 0.5s;
  -webkit-animation: ring 2s ease-in-out;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-delay: 0.5s;
  animation: ring 2s ease-in-out;
  animation-iteration-count: infinite;
  animation-delay: 0.5s;
}

@-webkit-keyframes pulsating {
  0% {opacity: 0.0;}
  50% {opacity: 0.2;}
  100% {opacity: 0.0;}
}

@keyframes pulsating {
  0% {opacity: 0.0;}
  50% {opacity: 0.2;}
  100% {opacity: 0.0;}
}

@-webkit-keyframes ring {
  0% {-webkit-transform: scale(0.4, 0.4); opacity: 0.0;}
  50% {opacity: 0.6;}
  100% {-webkit-transform: scale(1.1, 1.1); opacity: 0.0;}
}

@keyframes ring {
  0% {-webkit-transform: scale(0.4, 0.4);transform: scale(0.4, 0.4); opacity: 0.0;}
  50% {opacity: 0.6;}
  100% {-webkit-transform: scale(1.1, 1.1);transform: scale(1.1, 1.1); opacity: 0.0;}
}


.pointer {
  position: absolute;
  width: 70px;
  top: 35px;
  -webkit-animation: circling 2s linear;
  -webkit-animation-iteration-count: infinite;
  -moz-animation: circling 2s linear;
  -moz-animation-iteration-count: infinite;
  animation: circling 2s linear;
  animation-iteration-count: infinite;
  z-index: 20;
}

.pointer div {
  width: 49%;
  border-bottom:2px solid #165cb7;
}

.dot {
  opacity: 0;
  border: 3px solid #8eb716;
  border-radius: 100%;
  position:absolute;
  -webkit-animation: blink 2s ease-out;
  -webkit-animation-iteration-count: infinite;
  -moz-animation: blink 2s ease-out;
  -moz-animation-iteration-count: infinite;
  animation: blink 2s ease-out;
  animation-iteration-count: infinite;
  z-index: 25;
}

.dot.pos1 {
  left:10px;
  top:38px;
}

.dot.pos2 {
  left:40px;
  top:18px;
  -webkit-animation-delay: 0.6s;
  animation-delay: 0.6s;
}

@-webkit-keyframes circling {
  0% {-webkit-transform: rotate(0deg);}
  50% {-webkit-transform: rotate(180deg);}
  100% {-webkit-transform: rotate(360deg);}
}

@keyframes circling {
  0% {-webkit-transform: rotate(0deg);transform: rotate(0deg);}
  50% {-webkit-transform: rotate(180deg);transform: rotate(180deg);}
  100% {-webkit-transform: rotate(360deg);transform: rotate(360deg);}
}

@-webkit-keyframes blink {
  0% { opacity: 1; }
  100% { opacity: 0; }
}

@keyframes blink {
  0% { opacity: 1; }
  100% { opacity: 0; }
}





.medal:hover{
  -webkit-filter: drop-shadow(rgba(252, 252, 252, 0.782) 0px 2px 2px);
          filter: drop-shadow(rgba(252, 252, 252, 0.782) 0px 2px 2px);
}
.medal{
  -webkit-filter: drop-shadow(rgb(0, 0, 0) 0px 2px 2px);
          filter: drop-shadow(rgb(0, 0, 0) 0px 2px 2px);
  width: inherit;
}

.medal_modal{
  width: 300px;
  border-radius: 5px;
  position: absolute;
  top: 184px;
  left: 210px;
  background-color: rgb(0 0 0 / 90%);
padding: 20px;
}


.slit-in-vertical {
	-webkit-animation: slit-in-vertical 0.45s ease-out both;
	        animation: slit-in-vertical 0.45s ease-out both;
}

.swing-in-top-fwd {
	-webkit-animation: swing-in-top-fwd 0.5s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
	        animation: swing-in-top-fwd 0.5s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
}
.scale-down-center {
    -webkit-animation: stamp-scale 0.7s steps(2, end) both;
    animation: stamp-scale 0.7s steps(2, end) both;
  }

  .stamp-animation {
    -webkit-animation: stamp-scale 0.7s cubic-bezier(0.68, -0.55, 0.27, 1.55) both;
    animation: stamp-scale 0.7s cubic-bezier(0.68, -0.55, 0.27, 1.55) both;
  }
  
  @-webkit-keyframes stamp-scale {
    0% {
      -webkit-transform: scale(1.5); /* Start with a larger size */
      transform: scale(1.5);
    }
    100% {
      -webkit-transform: scale(1); /* Rapidly scale down to the original size */
      transform: scale(1);
    }
  }
  @keyframes stamp-scale {
    0% {
      -webkit-transform: scale(1.5); /* Start with a larger size */
      transform: scale(1.5);
    }
    100% {
      -webkit-transform: scale(1); /* Rapidly scale down to the original size */
      transform: scale(1);
    }
  }
  @-webkit-keyframes scale-up-center {
    0% {
      -webkit-transform: scale(2.5); /* Start with a larger size */
      transform: scale(2.5);
    }
    100% {
      -webkit-transform: scale(1); /* Scale down to the original size */
      transform: scale(1);
    }
  }
  
  @keyframes scale-up-center {
    0% {
      -webkit-transform: scale(2.5); /* Start with a larger size */
      transform: scale(2.5);
    }
    100% {
      -webkit-transform: scale(1); /* Scale down to the original size */
      transform: scale(1);
    }
  }
  
@-webkit-keyframes swing-in-top-fwd {
    0% {
      -webkit-transform: rotateX(-100deg);
              transform: rotateX(-100deg);
      -webkit-transform-origin: top;
              transform-origin: top;
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateX(0deg);
              transform: rotateX(0deg);
      -webkit-transform-origin: top;
              transform-origin: top;
      opacity: 1;
    }
  }
  @keyframes swing-in-top-fwd {
    0% {
      -webkit-transform: rotateX(-100deg);
              transform: rotateX(-100deg);
      -webkit-transform-origin: top;
              transform-origin: top;
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateX(0deg);
              transform: rotateX(0deg);
      -webkit-transform-origin: top;
              transform-origin: top;
      opacity: 1;
    }
  }
  
@-webkit-keyframes slit-in-vertical {
    0% {
      -webkit-transform: translateZ(-800px) rotateY(90deg);
              transform: translateZ(-800px) rotateY(90deg);
      opacity: 0;
    }
    54% {
      -webkit-transform: translateZ(-160px) rotateY(87deg);
              transform: translateZ(-160px) rotateY(87deg);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateZ(0) rotateY(0);
              transform: translateZ(0) rotateY(0);
    }
  }
  @keyframes slit-in-vertical {
    0% {
      -webkit-transform: translateZ(-800px) rotateY(90deg);
              transform: translateZ(-800px) rotateY(90deg);
      opacity: 0;
    }
    54% {
      -webkit-transform: translateZ(-160px) rotateY(87deg);
              transform: translateZ(-160px) rotateY(87deg);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateZ(0) rotateY(0);
              transform: translateZ(0) rotateY(0);
    }
  }
  
.App {
  text-align: left;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: left;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

