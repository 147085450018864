.medal:hover{
  filter: drop-shadow(rgba(252, 252, 252, 0.782) 0px 2px 2px);
}
.medal{
  filter: drop-shadow(rgb(0, 0, 0) 0px 2px 2px);
  width: inherit;
}

.medal_modal{
  width: 300px;
  border-radius: 5px;
  position: absolute;
  top: 184px;
  left: 210px;
  background-color: rgb(0 0 0 / 90%);
padding: 20px;
}


.slit-in-vertical {
	-webkit-animation: slit-in-vertical 0.45s ease-out both;
	        animation: slit-in-vertical 0.45s ease-out both;
}

.swing-in-top-fwd {
	-webkit-animation: swing-in-top-fwd 0.5s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
	        animation: swing-in-top-fwd 0.5s cubic-bezier(0.175, 0.885, 0.320, 1.275) both;
}
.scale-down-center {
    -webkit-animation: stamp-scale 0.7s steps(2, end) both;
    animation: stamp-scale 0.7s steps(2, end) both;
  }

  .stamp-animation {
    -webkit-animation: stamp-scale 0.7s cubic-bezier(0.68, -0.55, 0.27, 1.55) both;
    animation: stamp-scale 0.7s cubic-bezier(0.68, -0.55, 0.27, 1.55) both;
  }
  
  @-webkit-keyframes stamp-scale {
    0% {
      -webkit-transform: scale(1.5); /* Start with a larger size */
      transform: scale(1.5);
    }
    100% {
      -webkit-transform: scale(1); /* Rapidly scale down to the original size */
      transform: scale(1);
    }
  }
  @keyframes stamp-scale {
    0% {
      -webkit-transform: scale(1.5); /* Start with a larger size */
      transform: scale(1.5);
    }
    100% {
      -webkit-transform: scale(1); /* Rapidly scale down to the original size */
      transform: scale(1);
    }
  }
  @-webkit-keyframes scale-up-center {
    0% {
      -webkit-transform: scale(2.5); /* Start with a larger size */
      transform: scale(2.5);
    }
    100% {
      -webkit-transform: scale(1); /* Scale down to the original size */
      transform: scale(1);
    }
  }
  
  @keyframes scale-up-center {
    0% {
      -webkit-transform: scale(2.5); /* Start with a larger size */
      transform: scale(2.5);
    }
    100% {
      -webkit-transform: scale(1); /* Scale down to the original size */
      transform: scale(1);
    }
  }
  
@-webkit-keyframes swing-in-top-fwd {
    0% {
      -webkit-transform: rotateX(-100deg);
              transform: rotateX(-100deg);
      -webkit-transform-origin: top;
              transform-origin: top;
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateX(0deg);
              transform: rotateX(0deg);
      -webkit-transform-origin: top;
              transform-origin: top;
      opacity: 1;
    }
  }
  @keyframes swing-in-top-fwd {
    0% {
      -webkit-transform: rotateX(-100deg);
              transform: rotateX(-100deg);
      -webkit-transform-origin: top;
              transform-origin: top;
      opacity: 0;
    }
    100% {
      -webkit-transform: rotateX(0deg);
              transform: rotateX(0deg);
      -webkit-transform-origin: top;
              transform-origin: top;
      opacity: 1;
    }
  }
  
@-webkit-keyframes slit-in-vertical {
    0% {
      -webkit-transform: translateZ(-800px) rotateY(90deg);
              transform: translateZ(-800px) rotateY(90deg);
      opacity: 0;
    }
    54% {
      -webkit-transform: translateZ(-160px) rotateY(87deg);
              transform: translateZ(-160px) rotateY(87deg);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateZ(0) rotateY(0);
              transform: translateZ(0) rotateY(0);
    }
  }
  @keyframes slit-in-vertical {
    0% {
      -webkit-transform: translateZ(-800px) rotateY(90deg);
              transform: translateZ(-800px) rotateY(90deg);
      opacity: 0;
    }
    54% {
      -webkit-transform: translateZ(-160px) rotateY(87deg);
              transform: translateZ(-160px) rotateY(87deg);
      opacity: 1;
    }
    100% {
      -webkit-transform: translateZ(0) rotateY(0);
              transform: translateZ(0) rotateY(0);
    }
  }
  